import React, { useContext, useState } from 'react'
import "./Resume.scss";
import moment from 'moment';
import { formatStringNumber } from '../../services/Helpers/fonctions';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../services/Context/Context';
function Resume({cmcStatparAnn,statCmcParCategorie}) {
let sum = 0; let _sum = 0;
  const navigate = useNavigate();
  const { privileges } = useContext(UserContext);
  return (
    <div className='resume'>  
       <div className='arrays'>
        <div className='array'>
           <div className='header'>
            <h4>Resumé des Opérations pour les cinq (5) derniers jours. </h4>
            {privileges.filter((p) => p.privilege === 'StatistiqueParJourCategorieCMC').length > 0 &&
              <p onClick={() =>  navigate('/cmc/statistiques/parjourcategorie')}>Voir Tous</p>}
           </div> 
            <table>
                <thead>
                    <tr>
                        <th>N°Ord</th>
                        <th>Type d'engin</th>
                        <th>Date</th>
                        <th>Nombre</th>
                        <th>Total(fg)</th>
                </tr>
                </thead>
                <tbody>
                {cmcStatparAnn?.length > 0 && 
                 cmcStatparAnn.map((stat,index) => {
                //   setSum( sum + parseInt(stat.Total) * 100000 );  
                  sum = sum + parseInt(stat.Total) * 100000;
                  return(  
                    <tr key={index}>
                        <td>{ index + 1 }</td>
                        <td>{ stat?.categorie }</td>
                        <td>{ moment(stat.dateCreation).format('dddd,Do/MM/YY')} </td>
                        <td>{ stat.Total }</td>
                        <td> { formatStringNumber(parseInt(stat.Total) * 100000) }</td>
                   </tr>)
                 })
                }    
               
               
                </tbody>
                <tfoot>
                    <tr>
                    <td colSpan={4}>Total</td> 
                    <td>{ formatStringNumber(sum) }</td>
                    </tr>
                </tfoot>
            </table>    
        </div> 
        <div className='array'>
          <div className='header'>
             <h4>Immatriculation par Categorie. </h4>
             {privileges.filter((p) => p.privilege === 'StatistiqueParCategorieCMC').length > 0 &&
               <p onClick={() =>  navigate('/cmc/statistiques/parcategorie')}>Voir Tous</p>
             }
          </div> 
          <table>
                <thead>
                    <tr>
                        <th>N°Ord</th>
                        <th>Type d'engin</th>
                        <th>Nombre</th>
                        <th>Montant Total(fg)</th>
                </tr>
                </thead>
                <tbody>
                 {statCmcParCategorie?.length > 0 && statCmcParCategorie.map((stat,index) => {
                    _sum = _sum + parseInt(stat.Total) * 100000;
                    return (
                        <tr key={index + 1}>
                            <td>{ index + 1 }</td>
                            <td>{ stat.categorie }</td>
                            <td>{ stat.Total }</td>
                            <td>{ formatStringNumber(parseInt(stat.Total) * 100000) }</td>
                        </tr>
                    )
                 })   
                 
                  }
                 
                </tbody>
                <tfoot>
                    <tr>
                    <td colSpan={3} style={{ textAlign:'left' }}>Total</td> 
                    <td style={{ textAlign:'center' }}>{formatStringNumber(_sum)}</td>
                    </tr>
                </tfoot>    
           </table>     
        </div>   
       </div>
    </div>
  )
}

export default Resume