import React, { useContext, useEffect, useState } from 'react';
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';
import { ToWords } from 'to-words';
import Box from "@mui/material/Box";
import { StringToUpperCase, formatStringNumber, objecttoFormData } from '../../services/Helpers/fonctions';
import Erreurs from '../../components/Erreurs/Erreurs';
import { useNavigate } from 'react-router-dom';
import Api from '../../services/Api';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/atoms/loadingAtom';
import moment from 'moment/moment';
import { UserContext } from '../../services/Context/Context';
import ToolbarCMC from '../../components/ToolBarPaiementListCMC/ToolBarPaiementListCMC';
import { useGridApiRef } from '@mui/x-data-grid';
export default function PaiementCMCList() {
 
    const navigate = useNavigate();
    const [ filterData,setFilterData ] = useState({
      startDate: '',
      endDate: '',
    });
    const [montantValider,setMontantValider ] = useState(0);
    const[ pageState,setPageState ] = useState({
      data:[],
      total: 0,
      page: 1,
      pageSize: 10 
    });
    const [sortModel, setSortModel] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const api = new Api();
   
    const toWords = new ToWords({
        localeCode: 'fr-FR',
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
        }
      });
    const [erreurs, setErreurs ] = useState([]);  
    const [isLoading,setIsLoading] = useRecoilState(loadingState);

    const handleInput = (e) => {
      if(filterModel?.items?.length > 0 )
         setFilterModel({ items: [] });

        setFilterData({...filterData,[e.target.name]:e.target.value })
    } 
    
    const submitSearch = async (e) => {
      e.preventDefault();
      //console.log(filterData)
      var formdata ;var url = `/paiement/cmc/filter?options='default'&pageSize=${pageState.pageSize}&currentPage=${pageState.page}`
      
      if( filterModel?.items?.length > 0 ){
         url = `/paiement/cmc/filter?options='soft_filter'&pageSize=${pageState.pageSize}&currentPage=${pageState.page}`;
         formdata = objecttoFormData(filterData);
         formdata.append('params',JSON.stringify(filterModel?.items));

      }
      else {
       if( typeof filterData.params !== 'undefined'){
         const state = { startDate : filterData.startDate, endDate: filterData.endDate }
          setFilterData(state);
        }
        formdata = objecttoFormData(filterData);
      }
      setErreurs([]);setIsLoading(true);
      const { status,data,errors }  =  await api.apiData("post",url,formdata);
   
      setIsLoading(false);
    
      setPageState((prev) => ({...prev,pagedata:[]}))
     
      if( status === 200 ){
        
           if( data?.data.length > 0 ){
             const _dataValideStatus = data.data.filter((p) => p.status === 'Validé' );
             setMontantValider(_dataValideStatus.length * 100000);
            const arr = data?.data.map((item,index) => {
           
             return {
               ordre: data.from + index,
               id: item.paiement_id,
               categorie: item.nomCategorie,
               reference: item.reference,
               fullName: item.type_client === "Personnel" ? item.prenomClient + " "+ item.nomClient:item.nom_societe,
               chassis: item.chassis,
               email:item.emailClient ?item.emailClient:"Non fournie",
               telephoneClient: item.telephoneClient,
               nom_agence:item.nom_agence,
               localite:item.prefecture,
               prenomnomAgent: item.prenomAgent + " " + item.nomAgent,
               date: moment(item.created_at).format("Do MMMM  YYYY"),
               status:item.status,
               type_client: item.type_client
               
             }
           }); 
        
            setPageState((prev) => ({...prev,data: arr,total:data.total}));
          }
         
      }  
      else setErreurs(errors);
      
    } 
    const columns = [
      {
        field: "ordre",
        headerName: "N°",
        minWidth: 80,
      },
      {
        field: "categorie",
        headerName: "Categorie",
        minWidth: 180
      },
      {
        field: "chassis",
        headerName: "chassis",
        minWidth: 180
      },
      {
        field: "reference",
        headerName: "Référence",
        minWidth: 210
      },
      {
        field: "type_client",
        headerName: "Type Client",
        minWidth: 210
      },
      {
        field: "fullName",
        headerName: "Nom Complet",
        minWidth: 240
      },
      {
        field: "telephoneClient",
        headerName: "Téléphone du Client",
        minWidth: 120
      },
      {
        field: "nom_agence",
        headerName: "Agence",
        minWidth: 150
      },
      {
        field: "prenomnomAgent",
        headerName: "Prenom et Nom Agent",
        minWidth: 150
      },
      {
        field: "localite",
        headerName: "Localité",
        minWidth: 150
      },
     
      {
        field: "status",
        headerName: "status",
        minWidth: 150,
       
        renderCell: (params) => {
          return (
           <div className='status'>
             <div className={`${params.row.status === `Validé`?"valider":params.row.status === `Annulé`?"annuler":"default-status"}`}>{ params.row.status }</div>
          </div>)
        }
      },
      {
        field: "date",
        headerName: "Date de Crt.",
        minWidth: 150,
      
      },
      {
        field: "options",
        headerName: "Options",
        sortable: false,
        flex: 1,
        hide:false,
        filterable: false ,
        sortable:false,
        minWidth: 150,
        renderCell: (params) => {
          const id = params.id;
          return (
            <div className="options">
               <button onClick={() => navigate(`/cmc/details/${id}`)} >Voir facture</button>
            </div>
          );
        },
     }
    ];
    const getPaiements = async () => {
      setErreurs([]);setIsLoading(true);
      
      //  const { status,data,errors } = await api.apiData("get",`/paiement/cmc/liste?pageSize=${pageState.pageSize}&currentPage=${pageState.page}`);
      var formdata = new FormData();
      var url = `/paiement/cmc/liste?pageSize=${pageState.pageSize}&currentPage=${pageState.page}`
      if( filterData?.startDate || filterData?.endDate ){
        formdata.append('filterDate',JSON.stringify(filterData));
        url = url + '&filterData='+JSON.stringify(filterData);
      }
      const { status,data,errors } = await api.apiData("get",url,formdata);
  
     
    setIsLoading(false);
     setPageState((prev) => ({...prev,data:[]}))
     
     if( status === 200 ){
       
          if( data?.data.length > 0 ){
            const _dataValideStatus = data.data.filter((p) => p.status === 'Validé' );
            setMontantValider(_dataValideStatus.length * 100000);
           const arr = data?.data.map((item,index) => {
          
            return {
              ordre: data.from + index,
              id: item.paiement_id,
              categorie: item.nomCategorie,
              reference: item.reference,
              fullName: item.type_client === "Personnel" ? item.prenomClient + " "+ item.nomClient:item.nom_societe,
              chassis: item.chassis,
              email:item.emailClient ?item.emailClient:"Non fournie",
              telephoneClient: item.telephoneClient,
              nom_agence:item.nom_agence,
              localite:item.prefecture,
              prenomnomAgent: item.prenomAgent + " " + item.nomAgent,
              date: moment(item.created_at).format("Do MMMM  YYYY"),
              status:item.status,
              type_client: item.type_client
              
            }
          }); 
       
           setPageState((prev) => ({...prev,data: arr,total:data.total}));
         }
        
     }  
     else setErreurs(errors);
    }
    const _filterData = async () => {
      //veriable pour verifier si le filtre est active
     
       if(filterModel.items.length > 0 ){
         var formdata = new FormData() ;
         var url = `/paiement/cmc/liste?pageSize=${pageState.pageSize}&currentPage=${pageState.page}`;
         formdata = objecttoFormData(filterModel);
       
         if( filterData?.startDate || filterData?.endDate ){
            formdata.append('filterDate',JSON.stringify(filterData));
          //  formdata.append('params',JSON.stringify(filterModel?.items));
        }
         setIsLoading(true);
         const { status,data,errors } = await api.apiData("post",url,formdata);
         setIsLoading(false); 

         if( status === 200 ){
           
              if( data?.data?.length > 0 ){
                const _dataValideStatus = data.data.filter((p) => p.status === 'Validé' );
                setMontantValider(_dataValideStatus.length * 100000);
               const arr = data?.data.map((item,index) => {
         
                return {
                  ordre: data.from + index,
                  id: item.paiement_id,
                  categorie: item.nomCategorie,
                  reference: item.reference,
                  fullName: item.type_client === "Personnel" ? item.prenomClient + " "+ item.nomClient:item.nom_societe,
                  chassis: item.chassis,
                  email:item.emailClient ?item.emailClient:"Non fournie",
                  telephoneClient: item.telephoneClient,
                  nom_agence:item.nom_agence,
                  localite:item.prefecture,
                  prenomnomAgent: item.prenomAgent + " " + item.nomAgent,
                  date: moment(item.created_at).format("Do MMMM  YYYY"),
                  status:item.status,
                  type_client: item.type_client
                  
                }
              }); 
           
               setPageState((prev) => ({...prev,data: arr,total:data.total}));
             }
            
         }  
         else setErreurs(errors);
        
     }
   }
    useEffect(() => {
      
      if( filterModel?.items?.length === 0 ) 
         getPaiements();
      else 
        _filterData() 

    
    },[pageState.page,pageState.pageSize,filterModel]);
    const apiRef = useGridApiRef();
    return (
        <div className='payment-list page'>
            <DocumentTitle title="Liste de paiements des CMC" />
            <div className='header'>
              <h3>Liste de paiements des certificats</h3>
                <p>
                paiements effectués |  
                <span>
                    <strong> Montant Global : </strong>
                    {toWords.convert(montantValider) + " Francs Guinéens"} (
                    <strong>{formatStringNumber(montantValider)+ " fg"}</strong>)
                </span>
                </p>
            </div> 
            <div className="filters">
            <form onSubmit={submitSearch}>

          <h4>Filtres</h4>
          <div className="input-group">
            <label>
              Date début
              <input type="date" name="startDate" 
                 id="startDate" 
                 placeholder="Date de début"
                 onChange={handleInput}
                 />
            </label>
            <label>
              Date fin
              <input type="date" name="endDate"
                 id="endDate" 
                 placeholder="Date de fin"
                 onChange={handleInput}
                 />
            </label>
          </div>
          <Erreurs validation = {erreurs} />
          <button type="submit">Rechercher</button>
        </form>
        </div>  
        <Erreurs validation={erreurs} />
    
        
         <div className="array">
          <Box sx={{ height: "100vh", width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: ToolbarCMC }}
              // rows={paiementData}
              columns={columns}
              autoPageSize
              pagination
              disableSelectionOnClick
              rowCount={pageState.total}
              rows={pageState.data}
              page={pageState.page - 1 }
              localeText={ frFR.components.MuiDataGrid.defaultProps.localeText }
              paginationMode='server'
              slots={{ toolbar: GridToolbar }}
              filterMode="server"
              onFilterModelChange={(newFilterModel) => {
                setFilterModel(newFilterModel);
                // setFilterData({startDate:'',endDate:''})
              
              }}
              onPageSizeChange={(newpageSize, details ) => {
                // Maybe save into state
                setPageState((prev) => ({...prev,pageSize:newpageSize}))
              }}
              onPageChange={(newPage) => {
                setPageState(prev => ({ ...prev, page: newPage + 1 }))
              }}
              apiRef={apiRef}
              // componentsProps={{
              //   toolbar: {
              //     csvOptions: { disableToolbarButton: false },
              //     printOptions: { disableToolbarButton: false },
              //     // showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 250 },
              //   },
              // }}
              componentsProps={{ toolbar: { filterModel,filterData,isLoading,setIsLoading } }}
              // experimentalFeatures={{ newEditingApi: true }}
              />
          </Box>
        </div>
    
     </div>
  )
}
